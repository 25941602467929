<template>
  <div class="main-box">
    <tips :farmId="farmId" :farmerName="farmerName" :farmNam="farmName" />
  </div>
</template>

<script>
import tips from "./subForm/tips";

export default {
  name: "advicerTips",
  components: {
    tips,
  },
  data() {
    return {
      farmId: 0,
      farmerName:
        "لیست تمام دستورالعمل های زمین های مرتبط با شما (برای ثبت دستورالعمل یک زمین را انتخاب کنید)",
      farmName: "",
    };
  },
  computed: {},
  methods: {
    GetFarmTips(farmId, farmer) {
      this.farmId = farmId;
      this.farmerName = `دستورالعمل های ثبت شده برای  -- ${farmer}`;
      this.farmName = farmer;
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.main-box {
  width: 100%;
}
</style>